import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Container, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { setEventsFilters } from 'redux/eventsFiltersReducer';

import './Events.scss';
import Config from "config";
import EventsTable from './components/EventsTable';
import EventTypeFilters from './components/EventTypeFilters';
import EventsCategoryFilters from './components/EventsCategoryFilters';
import EventsAvailabilityFilters from './components/EventsAvailabilityFilters';
import { PEER_POP_UPS, BOOK_STUDIES, WRITING_CHALLENGES, MONTHLY_WEBINAR, LIVE_WRITE_IN } from './utils';

const backend_url = Config.BASE_API_URL;

const Events = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const eventsFilters = useSelector((state: any) => state.eventsFilters);
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<number[]>([]);

  const types = [
    { name: "Workshops with Pros", description: "Small-group feedback-focused events with literary agents, editors, or authors.", key: "workshop" },
    { name: MONTHLY_WEBINAR, description: "Classes and lectures on craft, process and the business of writing.", key: "monthly_webinar" },
    { name: "Classes/Intensives", description: "Dive into an element of craft or process; these events do not offer feedback.", key: "class_intensive" },
    { name: LIVE_WRITE_IN, description: "Meet with members of the community for live, shared writing time.", key: "live_write_in" },
    { name: PEER_POP_UPS, description: "Short-term critique groups where members exchange one round of submissions and critiques. <br/>Great for meeting writing partners and finding fresh eyes.", key: "peer_pop_up" },
    { name: BOOK_STUDIES, description: "Short-term study groups where members read and discuss a book together. Any member in our community can propose/start a book study.", key: "book_studies" },
    { name: WRITING_CHALLENGES, description: "Short-term critique groups where members exchange one round of submissions and critiques. <br/>Great for meeting writing partners and finding fresh eyes.", key: "writing_challenges" },
  ];

  const categories = [
    { name: "Middle Grade and Young Adult", key: 2 },
    { name: "Adult Fiction/Creative Nonfiction", key: 3 },
    { name: "Picture Books", key: 1 },
    { name: "Other", key: 4 },
  ];

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const typesParam = params.get('types');
    const categoriesParam = params.get('categories');

    if (typesParam) {
      handleTypeChange(typesParam.split(','));
    }

    if (categoriesParam) {
      setSelectedCategories(categoriesParam.split(',').map(Number));
    }
  }, [location.search]);

  const handleTypeChange = (selected: string[]) => {
    setSelectedTypes(selected);
    const params = new URLSearchParams(location.search);
    if (selected.length > 0) {
      params.set('types', selected.join(','));
    } else {
      params.delete('types');
    }
    history.push({ search: params.toString() });
    dispatch(setEventsFilters({
      type: selected.reduce((acc, type) => ({ ...acc, [type]: true }), {}),
      category: eventsFilters.category,
      free: eventsFilters.free,
      available: eventsFilters.available,
    }));
  };

  const handleCategoryChange = (selected: number[]) => {
    setSelectedCategories(selected);
    const params = new URLSearchParams(location.search);
    if (selected.length > 0) {
      params.set('categories', selected.join(','));
    } else {
      params.delete('categories');
    }
    history.push({ search: params.toString() });
    dispatch(setEventsFilters({
      type: eventsFilters.type,
      category: selected.reduce((acc, category) => ({ ...acc, [category]: true }), {}),
      free: eventsFilters.free,
      available: eventsFilters.available,
    }));
  };

  return (
    <Container className="ink-calendar">
      <Row>
        <Col md={{ span: 10, offset: 1 }}>
          <div className="ink-calendar__content">
            <h1>Events</h1>
            <Row>
              <Col sm={12} md={8}>
                <p className="grey">Events are a backbone of our community. With a wide variety to choose from across many types and genres, these are designed to help you improve your writing and grow your community.</p>
                <span className="accent">If you’re an Inked Voices member, many are free to you with your membership.</span>
                <br /><br />
              </Col>
              <Col sm={12} md={4} className="img-container">
                <img className="laptop-drawing" src={backend_url + "static/img/laptop-drawing.png"} />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <EventTypeFilters types={types} selectedTypes={selectedTypes} onTypeChange={handleTypeChange} />
              </Col>
            </Row>
            <Row>
              <Col sm={9} className="category-container">
                <EventsCategoryFilters categories={categories} selectedCategories={selectedCategories} onCategoryChange={handleCategoryChange} />
              </Col>
              <Col sm={3}>
                <EventsAvailabilityFilters />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <p>
                  <EventsTable categories={categories} types={types} selectedTypes={selectedTypes} selectedCategories={selectedCategories} />
                </p>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Events;