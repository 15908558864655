import React, { useEffect, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Col, Container, Row } from "react-bootstrap";

import { formatDistanceToNow, parseISO } from 'date-fns';

import AuthenticationContext from "contexts/AuthenticationContext";
import useFetch from "custom-hooks/useFetch";

import config from "../../../config";
import { BaseGroup } from "types";

import './GroupAdmin.scss';
import InviteByEmailsForm from "components/invite-by-emails-form/InviteByEmailsForm";
import InviteUserForm from "components/invite-user-form/InviteUserForm";
import WelcomeNewMembersForm from "components/welcome-new-members-form/WelcomeNewMembersForm";
import MessageToGroupForm from "components/message-to-group-form/MessageToGroupForm";
import CloseGroupModal from "components/close-group-modal/CloseGroupModal";
import TransferGroupModal from "components/transfer-group-modal/TransferGroupModal";

const BASE_API_URL = config.BASE_API_URL;


const GroupAdmin = () => {
  const { groupId } = useParams() as any;
  const user = useSelector((state: any) => state.user);
  const [state, fetchData] = useFetch();
  const [group, setGroup] = useState<BaseGroup>();
  const [memberships, setMemberships] = useState([]);
  const [showGroupCloseModal, setShowGroupCloseModal] = useState(false);
  const [showGroupTransferModal, setShowGroupTransferModal] = useState(false);
  const authenticationContext = useContext(AuthenticationContext);

  useEffect(() => {
    fetchData({
      url: `api/v1.0/group/${groupId}/members/`,
      method: "GET",
      token: authenticationContext.token,
    }).then((res) => {
      setMemberships(res);
    }).catch((error) => {
      console.error("Error fetching group memberships data:", error);
    });
  }, [authenticationContext.token]);

  useEffect(() => {
    fetchData({
      url: `group/api/view/${groupId}/`,
      method: "GET",
      token: authenticationContext.token,
    }).then((res) => {
      setGroup(res);
    }).catch((error) => {
      console.error("Error fetching group memberships data:", error);
    });
  }, [authenticationContext.token]);

  if (!group) {
    return <div>Loading...</div>;
  }

  return (
      <Container id="membershipOptions" className="ink-group-admin membership-page">
        <h1 className="add-margin">Group Membership Options</h1>

        <Row>
          <Col md={3}></Col>
          <Col md={9}>
            <h2 className="add-margin">Manage Members</h2>
            
            <table style={{ width: "100%" }} className="table table-striped">
              <thead>
                <tr>
                  <th style={{ width: "30%" }}>Name</th>
                  <th style={{ width: "25%" }}>Last Active</th>
                  <th style={{ width: "15%" }}></th>
                  <th style={{ width: "15%" }}></th>
                  <th style={{ width: "15%" }}></th>
                </tr>
              </thead>
              <tbody>
                {memberships.map((member: any) => {
                  return (
                    <tr key={member.id}>
                      <td>{member.pen_name}</td>
                      {member.last_active !== null ? (
                        <td>{formatDistanceToNow(parseISO(member.last_active), { addSuffix: true })}</td>
                      ) : (
                        <td align="center"> -</td>
                      )}
                      <td>
                        <a href={`${BASE_API_URL}group/reminder_to_member/${groupId}/${member.id}`}>Remind</a>
                      </td>
                      <td>
                        <a href={`${BASE_API_URL}messages/compose/${member.user}`}>Message</a>
                      </td>
                      <td>
                        {member.id !== user.data.id &&
                          <a href={`${BASE_API_URL}group/kick/${groupId}/${member.user}`}>Remove</a>
                        }
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <p>
              <em>*The Send Reminder action will pre-populate reminder text into our messaging system for you to customize and send.</em>
            </p>

            <Row>
              <Col md={9}>
                <br/><br/>

                <h2 className="add-margin">Message your group</h2>
                <h5 className="add-margin">Send a message to the group</h5>

                <MessageToGroupForm groupId={groupId} />
                <br/><br/>

                <h2 className="add-margin">Welcome new members</h2>
                <WelcomeNewMembersForm groupId={groupId} />

                <h2 className="add-margin">Invite</h2>
                {/*  Invite user */}
                <InviteUserForm groupId={groupId} />

                {/* Invite by emails */}
                <InviteByEmailsForm groupId={groupId} />

                {group && user.data.id == group.owner ? (
                  <>
                    <br/><br/>
                    <Row>
                      <Col md={9}>
                        <h2 className="add-margin">Transfer ownership</h2>
                        <p className="list-group-item-text">Transfer group leadership to another member. This action invites a group member to become the group's main administrator.</p>
                      </Col>
                      {group && group.can_it_be_transferred && (
                        <>
                          <Col md={3} style={{ padding: "3% 0% 0% 3.5%" }}>
                            <button
                              type="button"
                              className="btn purple"
                              onClick={() => setShowGroupTransferModal(true)}
                            >
                              Transfer
                            </button>
                          </Col>
                          <TransferGroupModal
                            groupId={groupId}
                            groupUrl={group.url}
                            show={showGroupTransferModal}
                            handleClose={() => setShowGroupTransferModal(false)}
                          />
                        </>
                      )}
                    </Row>
                    <br/><br/>
                    <Row className="row">
                      <Col md={9}>
                        <h2 className="add-margin">Close group</h2>
                        <p className="list-group-item-text">Closing this group will archive its discussion board and remove all members. Writers will retain all of their critiques on their  Dashboards.</p>
                      </Col>
                      {group && group.closed_at === null && (
                        <>
                          <Col md={3} style={{ padding: "3% 0% 0% 0%" }}>
                            <button
                              type="button"
                              className="btn purple"
                              onClick={() => setShowGroupCloseModal(true)}
                            >
                              Close group
                            </button>
                          </Col>
                          {/* Modal */}
                          <CloseGroupModal
                            groupId={groupId}
                            groupName={group.name}
                            show={showGroupCloseModal}
                            handleClose={() => setShowGroupCloseModal(false)}
                            isSingleMember={memberships.length === 1}
                          />
                        </>
                      )}
                    </Row>
                  </>
                ) : (
                  <Row>
                    <Col md={9}>
                      <h2 className="add-margin">Leave group</h2>
                      <p className="list-group-item-text">Leave group.</p>
                    </Col>
                    <Col md={3} style={{ padding: "3% 0% 0% 3.5%" }}>
                      <a type="button" href="{% url 'leave_group' group.pk %}" className="btn purple" data-target="#" data-toggle="modal">Leave group</a>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
  );
}

export default GroupAdmin;
