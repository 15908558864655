// Static routes
export const homeRoute = '/';
export const teamRoute = '/team';
export const loginRoute = '/login';
export const logoutRoute = '/logout';
export const eventsRoute = '/events';
export const archiveRoute = '/archive';
export const dashboardRoute = '/dashboard';

// Group routes
export const newGroupRoute = '/group/new/';
export const groupAdminRoute = (groupId) => `/group/admin/${groupId}/`;
export const groupDetailRoute = (id, slug) => `/group/${id}/${slug}/`;
export const groupEditRoute = (groupId) => `/group/edit/${groupId}/`;
export const groupNotificationsRoute = (groupId) => `/group/notifications/${groupId}/`;
export const groupPublicProfileRoute = (id, name) => `/group/public/${id}/${name}/`;
export const groupSearchRoute = '/group/search/';
export const whoCritiquedRoute = (id) => `/group/${id}/who-critiqued/`;
