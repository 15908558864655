import { useContext, useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button, Col, Container, Row } from "react-bootstrap";
import useFetch from "custom-hooks/useFetch";
import sendRequest from "services/dataService";
import AuthenticationContext from "contexts/AuthenticationContext";
import { BaseUser, CurrentUserState } from "types";
import MessagesLayout from "../MessagesLayout";
import "./View.scss";
import Config from "config";
import { InkedMessage } from "../types/Message";


const initialMessage: InkedMessage = {
  sender: { pen_name: '', id: 0, profile_id: 0 },
  subject: '',
  body: '',
  sent_at: '',
  recipients: null
};

export default function View() {
  const backend_url = Config.BASE_API_URL?.slice(0, -1);
  const { messageId } = useParams<{ messageId: string }>();
  const history = useHistory();
  const [message, setMessage] = useState<InkedMessage>(initialMessage);

  const userState = useSelector(
    (state: { user: CurrentUserState }) => state.user
  );

  const [state, fetchData] = useFetch();
  const [users, setUsers] = useState<BaseUser[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<{ value: string; label: string }[]>([]);

  const userOptions = users.map((user: any) => ({
    value: user.id.toString(),
    label: user.pen_name,
  }));
  const authenticationContext = useContext(AuthenticationContext);

  const formatDate = (dateStr: string): string => {
    const d = new Date(dateStr);
    let formatted = d.toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
    formatted = formatted.replace(/^(\w{3}) /, '$1. ');
    return formatted;
  };

  const handleReplyAll = async (event: React.FormEvent) => {
    event.preventDefault();
    history.push({
      pathname: `/messages/reply`,
      state: { message },
    });
  };

  const handleReply = async (event: React.FormEvent) => {
    event.preventDefault();
    message.recipients = [];
    history.push({
      pathname: `/messages/reply`,
      state: { message },
    });
  };

  const handleDelete = async (event: React.FormEvent) => {
    event.preventDefault();
    const url = `api/v1.0/messages/${messageId}`;
    const method = "DELETE";
    const token = authenticationContext.token || '';
    const data = await sendRequest({
      url, method, token,
      body: undefined
    });
    if (data) {
      history.push("/messages/inbox");
    }
  };

  useEffect(() => {
    fetchData({
      url: `api/v1.0/messages/${messageId}`,
      method: "GET",
      token: authenticationContext.token,
    })
      .then((data) => {
        setMessage({
          sender: { pen_name: data.sender.pen_name, id: data.sender.id, profile_id: data.sender.profile_id },
          subject: data.subject,
          body: data.body,
          sent_at: data.sent_at,
          recipients: data.recipients || null,
        });
      })
      .catch((error) => {
        console.error("Error fetching message data:", error);
      });
  }, [authenticationContext.token, messageId]);

  const rightCol = (
    <Col md={9}>
      <h1>View Message</h1>
      <div className="view-message">
        <Row className="align-items-center">
          <Col md={6}>
            <h4>Sender</h4>
          </Col>
          <Col md={3}>
            <h4>Date</h4>
          </Col>
          <Col md={3} className="text-end">
            {message.recipients && message.recipients.length > 1 && (
              <Link onClick={handleReplyAll} to={""}>
              <img className="icon-img" src={`${backend_url}/static/img/reply_all.png`} alt="Reply All" style={{ margin: 0 }} />
              </Link>
            )}
            <Link onClick={handleReply} to={""}>
              <img className="icon-img" src={`${backend_url}/static/img/replyicon.png`} alt="Reply" style={{ margin: 0 }} />
            </Link>
            <Link onClick={handleDelete} className="p-0 ms-2" to={""}>
              <img className="icon-img" src={`${backend_url}/static/img/trash.png`} alt="Delete" style={{ margin: 0 }} />
            </Link>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col md={6}>
            <a href={`/profile/view/${message.sender.profile_id}`}>
              {message.sender.pen_name}
            </a>
          </Col>
          <Col md={6}>
            {formatDate(message.sent_at)}
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={6}>
            <h4>Subject</h4>
          </Col>
          <Col md={6}>
            <h4>To</h4>
          </Col>
        </Row>
        <Row className="mt-1">
          <Col>
            <strong>{message.subject}</strong>
          </Col>
          <Col>
            {message.recipients && message.recipients.map((recipient, index) => (
              <Link key={recipient.profile_id} to={`/profile/view/${recipient.profile_id}`}>
                {recipient.pen_name}
                {index < (message.recipients?.length || 0) - 1 && ', '}
              </Link>
            ))}
          </Col>
        </Row>
        <hr />
        <div className="message-body">
          <div dangerouslySetInnerHTML={{ __html: message.body.replace(/\n/g, '<br />') }} />
        </div>
      </div>
    </Col>
  );
  return (
    <Container className="view-message-container">
      <MessagesLayout
        unreadCount={userState.data.unread_messages || 0}
        rightCol={rightCol}
      />
    </Container>
  );
}