import React from 'react';
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import { teamRoute, eventsRoute } from 'routes/endpoints';
import './CommunityLedInitiatives.scss';


const CommunityLedInitiatives = () => {
  return (
    <Container className="container about-view ink-community-led-initiatives">
      <Row>
        <Col md={{span: 9, offset: 1}}>
          <Row>
            <h1>Community-led Initiatives</h1>

            <h2>Write-ins</h2>
            <p>
              <Link to={`${eventsRoute}/?types=live_write_in`}>Write-ins</Link> bring members together for shared writing time, either through a video call or a chat forum. 
              They provide accountability as well as a sense of community. Members host weekly write-ins at various 
              set times throughout the week. Don’t see a time that works for you? <Link to={teamRoute}>Contact</Link> your Community Lead and 
              we’ll help you host your own!
            </p>

            <h2>Book Studies</h2>
            <p>
              We periodically run short-term groups focused on working through a particular book of craft. Past groups 
              have studied–and shared exercises from–Lisa Cron’s Story Genius, Mary Oliver’s A Poetry Handbook, Trevor 
              Thrall’s The 12-Week Year for Writers, and Melissa Stewart’s Nonfiction Writers Dig Deep, among many others. 
              Have an idea for a book study group? <Link to={teamRoute}>Let us know!</Link>
            </p>

            <h2>Prompt Groups and Writing Challenges</h2>
            <p>
              Our member-led groups often include short-term prompt groups, in which members work on their craft via weekly 
              prompts and share what they have written. 
            </p>
            <p>
              Inked Voices also provides support to writers participating in 
              <a href='https://nanowrimo.org/' target="_blank" rel="noopener noreferrer"> NaNoWriMo </a> 
              and <a href='https://nanowrimo.org/what-is-camp-nanowrimo' target="_blank" rel="noopener noreferrer">Camp NaNoWrimo</a>. Members who join 
              our NaNoWriMo accountability groups will find camaraderie and support in achieving the goals they have set for themselves. 
            </p>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default CommunityLedInitiatives;
