import config from "config";
import { Button, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

interface MessagesLayoutProps {
  unreadCount: number;
  rightCol: React.ReactElement;
}

export default function MessagesLayout({
  rightCol,
  unreadCount
}: MessagesLayoutProps) {
  return (
      <Row>
        <Col md={{ span: 2 }} className="sidebar">
          <a href="/messages/compose/">
            <Button className="purple w-full">Compose</Button>
          </a>
          <div>
            <Link
              to="/messages/inbox/"
              className={unreadCount ? "font-bold" : ""}
            >
              Inbox ({unreadCount})
            </Link>
          </div>
          <div>
            <Link to="/messages/sent/">Sent Messages</Link>
          </div>
          <div>
            <Link to="/messages/trash/">Trash</Link>
          </div>
        </Col>
        {rightCol}
      </Row>
  );
}
