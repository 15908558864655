import { Alert, Button, Col, Container, Row } from "react-bootstrap";

import "./BaseMessages.scss";
import { useEffect, useState } from "react";
import config from "config";
import { Link, useLocation } from "react-router-dom";
import ReactPlaceholder from "react-placeholder";
import { BaseMessage, PaginationInfo } from "types";
import { displayDateTime } from "pages/events/utils";
import PaginationButtons from "components/pagination-buttons/PaginationButtons";
import SearchBar from "components/search-bar/SearchBar";
import MessageDeleteModal from "./components/modals/ConfirmDeleteModal";
import { toast } from "react-toastify";
import MessagesLayout from "./MessagesLayout";

export enum MessagesPageOptions {
  Inbox = "Inbox",
  Sent = "Sent",
  Trash = "Trash",
}

const MessageRow = ({
  message,
  page,
  onDelete,
}: {
  message?: BaseMessage;
  page: MessagesPageOptions;
  onDelete?: () => void;
}) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <tr className={!message || message?.read ? "" : "new"}>
      <td className="text-center">
        <ReactPlaceholder ready={Boolean(message)} type="text" rows={1}>
          <input
            type="checkbox"
            name="row-check"
            id={message?.id.toString()}
            value={message?.id}
          />
        </ReactPlaceholder>
      </td>
      <td>
        <ReactPlaceholder ready={Boolean(message)} type="text" rows={1}>
          <a href={`/messages/view/${message?.id}/`}>
            {page === MessagesPageOptions.Sent
              ? message?.recipient
              : message?.sender}
          </a>
        </ReactPlaceholder>
      </td>
      <td>
        <ReactPlaceholder ready={Boolean(message)} type="text" rows={1}>
          <a href={`/messages/view/${message?.id}/`}>
            {message?.subject}
          </a>
        </ReactPlaceholder>
      </td>
      <td>
        <ReactPlaceholder ready={Boolean(message)} type="text" rows={1}>
          <a href={`/messages/view/${message?.id}/`}>
            <div className="sm-text">{displayDateTime(message?.sent_at)}</div>
          </a>
        </ReactPlaceholder>
      </td>
      <td>
        <ReactPlaceholder ready={Boolean(message)} type="text" rows={1}>
          {page === MessagesPageOptions.Trash ? (
            <a href={config.BASE_API_URL + `messages/undelete/${message?.id}/`}>
              undelete
            </a>
          ) : (
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setShowModal(true);
              }}
            >
              <img
                src={config.BASE_API_URL + "static/img/trash.png"}
                width={25}
                height={25}
              />
            </a>
          )}
        </ReactPlaceholder>
      </td>
      {message && (
        <MessageDeleteModal
          messages={[message]}
          show={showModal}
          onHide={(deleted: boolean) => {
            setShowModal(false);
            onDelete && deleted && onDelete();
          }}
        />
      )}
    </tr>
  );
};

type BaseMessagesProps = {
  title: string;
  page: MessagesPageOptions;
  messages: BaseMessage[] | null;
  paginationInfo: PaginationInfo;
  unreadCount: number | null;
  onPageChange: (page: number) => void;
  onSearch: (search: string) => void;
  onDelete: () => void;
};

export default function BaseMessages({
  title,
  page,
  messages,
  paginationInfo,
  unreadCount,
  onPageChange,
  onSearch,
  onDelete,
}: BaseMessagesProps) {
  const [showModal, setShowModal] = useState(false);

  const [messagesToDelete, setMessagesToDelete] = useState<
    BaseMessage[] | null
  >(null);

  const queryParams = new URLSearchParams(useLocation().search);
  const restored = queryParams.get("restored");

  useEffect(() => {
    if (restored === "true") {
      toast.success("Message restored", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }, []);

  return (
    <Container className="inbox">
      <MessagesLayout 
        unreadCount={unreadCount ?? 0} 
        rightCol={
          <Col md={{ span: 10 }}>
          {page === MessagesPageOptions.Trash && (
            <Alert key="warning" variant="warning">
              Deleted messages are occasionally removed from the trash. Not a
              reliable area for long-term storage.
            </Alert>
          )}
          <h1>{title}</h1>
          <SearchBar onSearch={onSearch} />
          <table
            width="100%"
            id="inbox"
            className="table table-striped messages"
          >
            <thead>
              <tr>
                <th className="text-center">
                  <input
                    type="checkbox"
                    id="check_all"
                    onChange={(e) => {
                      const checkboxes = document.querySelectorAll(
                        'input[type="checkbox"]'
                      );
                      if (e.target.checked) {
                        Array.from(checkboxes).forEach((checkbox: any) => {
                          checkbox.checked = true;
                        });
                      } else {
                        Array.from(checkboxes).forEach((checkbox: any) => {
                          checkbox.checked = false;
                        });
                      }
                    }}
                  />
                </th>
                <th>
                  {page === MessagesPageOptions.Sent ? "Receiver" : "Sender"}
                </th>
                <th>Subject</th>
                <th>Date</th>
                <th>
                  <a
                    id="remove_all"
                    href="#"
                    style={{ display: "inline" }}
                    onClick={(e) => {
                      e.preventDefault();
                      const checkboxes = document.querySelectorAll(
                        'input[type="checkbox"]:checked'
                      );
                      setMessagesToDelete(
                        messages?.filter((m) => {
                          const ids = Array.from(checkboxes).map(
                            (checkbox) => checkbox.id
                          );
                          return ids.includes(m.id.toString());
                        }) || null
                      );
                      setShowModal(true);
                    }}
                  >
                    <img
                      src={config.BASE_API_URL + "static/img/trash.png"}
                      width={25}
                      height={25}
                    />
                  </a>
                </th>
              </tr>
            </thead>
            <tbody>
              {messages === null ? (
                <>
                  {Array.from({ length: 10 }).map((_, index) => (
                    <MessageRow key={index} page={page} />
                  ))}
                </>
              ) : (
                messages.map((message) => (
                  <MessageRow
                    message={message}
                    onDelete={onDelete}
                    page={page}
                  />
                ))
              )}
            </tbody>
          </table>

          {messages !== null && messages.length === 0 && (
            <div className="no-messages">No messages</div>
          )}
          {paginationInfo.current &&
            messages &&
            messages?.length > 0 &&
            paginationInfo.total &&
            paginationInfo.total > 1 && (
              <PaginationButtons
                currentPage={paginationInfo.current}
                totalPages={paginationInfo.total}
                onPageChange={onPageChange}
              />
            )}
        </Col>
        }
      
      />
      {messages && (
        <MessageDeleteModal
          messages={messagesToDelete || []}
          show={showModal}
          onHide={(deleted: boolean) => {
            setShowModal(false);
            deleted && onDelete();
          }}
        />
      )}
    </Container>
  );
}
