import React from 'react';
import { Alert } from 'react-bootstrap';

import './AlertMessage.scss';

interface AlertMessageProps {
  message: string;
  variant: string;
  onClose?: () => void;
  style?: React.CSSProperties;
}

const AlertMessage: React.FC<AlertMessageProps> = ({ message, variant, onClose, style }) => {
  return (
    <Alert
      className="iv-alert"
      variant={variant}
      onClose={onClose}
      dismissible={!!onClose}
      closeLabel={onClose ? "" : undefined}
      style={style}
    >
      <div className="text-center" dangerouslySetInnerHTML={{ __html: message }} />
    </Alert>
  );
};

export default AlertMessage;
