import config from "config";
import sendRequest from "services/dataService";
import { addDays, subDays } from 'date-fns';

export const loadPaginatedData = async (
  url: string,
  token: string,
  callback: CallableFunction,
  first: Boolean = true
) => {
  const response = await sendRequest({
    url,
    method: "GET",
    token: token,
    body: null,
  });
  callback(response, first);
  if (response.next) {
    // in prod next comes as "http"
    const next = response.next as string;
    const uri = next.startsWith(config.BASE_API_URL || "")
      ? next
      : next.replace("http://", "https://");
    loadPaginatedData(uri, token, callback, false);
  }
};

/**
 * Truncates a string to a specified length and adds an ellipsis if the original string is longer.
 * This function does not preserve HTML tags and might cut the string in the middle of a tag.
 * @param html The HTML string to truncate.
 * @param maxLength The maximum length of the truncated string.
 * @returns The truncated HTML string.
 */
export function truncateCharsHtml(
  html: string | undefined,
  maxLength: number
): string {
  if (!html) return "";

  if (html.length <= maxLength) return html;

  let truncated = html.substr(0, maxLength);

  // Optional: Attempt to avoid cutting in the middle of a word
  // Find the last space within the truncated string
  const lastSpace = truncated.lastIndexOf(" ");
  if (lastSpace > 0) {
    truncated = truncated.substr(0, lastSpace);
  }

  return truncated + "...";
}

export const countWords = (text: string) => {
  const temporaryDiv = window.document.createElement("div");
  temporaryDiv.innerHTML = text
    .replaceAll("&nbsp;", " ")
    .replaceAll("\n", ".")
    .replaceAll("<br>", "<br>.")
    .replaceAll("<br/>", "<br/>.")
    .replaceAll("</p>", ".</p>")
    .replaceAll("</li>", ".</li>");
  const textWithoutTags = temporaryDiv.innerText;
  const wordList = textWithoutTags.match(/\w+/g) || [];
  return wordList ? wordList.length : 0;
};

export function adjustTextareaHeight(
  el: HTMLTextAreaElement,
  defaultHeight: number = 90
) {
  const defaultHeightWithUnit = defaultHeight + "px";
  if (el.value.length === 0) {
    el.style.height = defaultHeightWithUnit;
    return;
  }
  const currentHeight = el.style.height || defaultHeightWithUnit;
  el.style.height =
    el.scrollHeight > el.clientHeight ? el.scrollHeight + "px" : currentHeight;
}

export const AutoLink = (text: string) => {
  const delimiter =
    /((?:https?:\/\/)?(?:(?:[a-z0-9]?(?:[a-z0-9\-]{1,61}[a-z0-9])?\.[^\.|\s])+[a-z\.]*[a-z]+|(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3})(?::\d{1,5})*[a-z0-9.,_\/~#&=;%+?\-\\(\\)]*)/gi;

  return (
    <>
      {text.split(delimiter).map((word) => {
        const match = word.match(delimiter);
        if (match) {
          const url = match[0];
          return (
            <a
              href={url.startsWith("http") ? url : `http://${url}`}
              target="_blank"
            >
              {url}
            </a>
          );
        }
        return word;
      })}
    </>
  );
};

export function getGenreListIds(genreObject: any) {
  let genres2_ids: any = [];

  genreObject.forEach((genre_super_category: any) =>
    genre_super_category.children.forEach((category: any) =>
      category.children.forEach(function (genre: any) {
        if (genre.selected === true) {
          genres2_ids.push(genre.id);
        }
      })
    )
  );

  return genres2_ids;
}

export const formatDate = (dateStr: string) => {
  const date_options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  } as const;
  const dt = new Date(dateStr);
  return dt.toLocaleDateString("en-US", date_options);
};

/**
 * Calc the date after or before a given date.
 * @param {Date | string} date - Start date.
 * @param {number} days - Number of days to add or subtract.
 * @param {"add" | "subtract"} direction - Type of operation to perform.
 * @returns {string} - Calculated date in ISO format.
 */
export const calculateDate = (date: Date | string, days: number, direction: "add" | "subtract"): string => {
  const parsedDate = typeof date === 'string' ? new Date(date) : date;
  let newDate;

  if (direction === "add") {
    newDate = addDays(parsedDate, days);
  } else if (direction === "subtract") {
    newDate = subDays(parsedDate, days);
  } else {
    throw new Error("Invalid direction. Use 'add' or 'subtract'.");
  }

  return formatDate(newDate.toISOString());
};
