import React, { useState } from "react";
import { Modal, Button, Spinner } from 'react-bootstrap';

import './CloseGroupModal.scss';

import config from "../../config";

const BASE_API_URL = config.BASE_API_URL;


interface CloseGroupModalProps {
  show: boolean;
  handleClose: () => void;
  groupId: number;
  groupName: string;
  isSingleMember: boolean;
}

const CloseGroupModal: React.FC<CloseGroupModalProps> = ({ show, handleClose, groupId, groupName, isSingleMember }) => {
  const [isConfirming, setIsConfirming] = useState(false);

  const handleConfirm = () => {
    setIsConfirming(true);
    if (isSingleMember) {
      localStorage.setItem('groupClosed', 'true');
      localStorage.setItem('closedGroupName', groupName);
    }
    window.location.href = `${BASE_API_URL}group/close-group/${groupId}/`;
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton closeLabel="" className="custom-close-button">
        <Modal.Title>Do you want to close this group?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>This action will close {groupName}. Do you want to proceed?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose} disabled={isConfirming}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleConfirm} disabled={isConfirming}>
          {isConfirming ? (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />{' '}
              Confirming...
            </>
          ) : (
            'Confirm'
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CloseGroupModal;
