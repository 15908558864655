import React from 'react';
import { useSelector } from "react-redux";
import { FullGroup } from 'types';

import config from "config";
import './GroupItem.scss';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';


const GroupItem = ({ group }: { group: FullGroup }) => {
  const user = useSelector((state: any) => state.user);

  const getSelectedGenres = (genres: any[]): string[] => {
    let selectedGenres: string[] = [];

    genres.forEach((genre) => {
      if (genre.selected) {
        selectedGenres.push(genre.name);
      }
      if (genre.children && genre.children.length > 0) {
        selectedGenres = selectedGenres.concat(getSelectedGenres(genre.children));
      }
    });

    return selectedGenres;
  };

  const selectedGenres = getSelectedGenres(group.genres2_set);
  const is_member = (user: any) => {
    return group.members?.some((member) => member.id === user.data.id);
  }
  const groupSlug = group.name.replace(/ /g, "-").toLowerCase();
  const groupNameEncoded = encodeURIComponent(group.name);

  return (
    <>
      <li className="groups-result">
        <div className="group-info">
          <div className="group-data">
            <p className="text-center">
              {group.avatar ? (
                <img src={group.avatar} />
              ) : (
                <>
                  <img src="" style={{ display: "none" }} alt="group profile" />
                  <span className="group-profile-picture"></span>
                </>
              )}
            </p>

            <h5>
              {is_member(user) ? (
                <a
                  id={`membership-${group.id}-group-name`}
                  href={group.public_url} target="_blank" rel="noopener noreferrer"
                >
                  {group.name}
                </a>
              ) : (
                <a href={group.public_url} target="_blank" rel="noopener noreferrer">{group.name}</a>
              )}
            </h5>
            <div className="text p-small group-description" dangerouslySetInnerHTML={{ __html: group.description }}></div>

            <p className="p-small">
              <strong>Genres</strong>
              <br />
              <span className="italic">
                {selectedGenres.map((genre: string, index: number) => (
                  <React.Fragment key={index}>
                    {genre}
                    {index < selectedGenres.length - 1 && " / "}
                  </React.Fragment>
                ))}
              </span>
            </p>

            {group.progroupsubscription && (
              <>
                <p><strong>Price:</strong> <span className="italic">{group.progroupsubscription.price}</span></p>
                <p>
                  <strong>From:</strong> 
                  <span className="italic">
                    {new Date(group.progroupsubscription.start_date).toLocaleDateString('en-us')}
                  </span>
                  &nbsp;-
                  <strong>To:</strong> 
                  <span className="italic">
                    {new Date(group.progroupsubscription.end_date).toLocaleDateString('en-us')}
                  </span>
                </p>
                <p><strong>Quota:</strong> <span className="italic">{group.max_size}</span></p>
              </>
            )}
          </div>

          <div className="group-footer">
            <div className="btn-container">
              {is_member(user) ? (
                <Button className="btn purple">
                  <a
                    id={`membership-${group.id}`}
                    href={group.public_url} target="_blank" rel="noopener noreferrer"
                    style={{ color: "white" }}
                  >
                    View Profile
                  </a>
                </Button>
              ) : (
                <Button>
                  <a href={`${group.public_url}`} target="_blank" rel="noopener noreferrer" style={{ color: "white" }}>
                    View Profile
                  </a>
                </Button>
              )}
              {!group.is_full ? (
                <>
                  {is_member(user) ? (
                    <button className="btn disabled-btn" disabled>Member</button>
                  ) : (
                    <>
                      {group.progroupsubscription ? (
                        <>
                          {group.progroupsubscription.group_didnt_start_yet ? (
                            <a href="{% url 'pre_pro_group_checkout' group.progroupsubscription.id %}"
                              role="button" className="btn blue" data-toggle="modal" data-target="#" >Join group</a>
                          ) : (
                            <button className="btn" disabled>Group started</button>
                          )}
                        </>
                      ) : (
                        <Button
                          href={`${config.BASE_API_URL}messages/compose/(${group.owner}/${groupNameEncoded}/`}
                          className="btn blue"
                        >
                          Request Invite
                        </Button>
                      )}
                    </>
                  )}
                </>
              ) : (
                <button className="btn" disabled>Group full</button>
              )}
            </div>
            {group.members?.length} Members <span className="pull-right"><strong>Goal</strong> {group.min_size}-{group.max_size} Members</span>
          </div>
        </div>
     </li>
    </>
  );
}

export default GroupItem;