import config from "config";
import AuthenticationContext from "contexts/AuthenticationContext";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import sendRequest from "services/dataService";
import { BaseUser, Membership } from "types";

import "./WhoCritiqued.scss";

type CellProps = {
  key?: any;
  children: React.ReactNode;
  disabled?: boolean;
};
const Cell = ({ key, children, disabled }: CellProps) => {
  return (
    <td
      className={
        "p-4 align-middle [&amp;:has([role=checkbox])]:pr-0 text-center border-r " +
        (disabled ? " bg-muted" : "")
      }
      key={key}
      title={disabled ? "This user made this submission" : ""}
    >
      {!disabled && children}
    </td>
  );
};
const TitleCell = ({
  id,
  title,
  owner_pen_name,
}: {
  id: number;
  title: string;
  owner_pen_name: string;
}) => {
  const link = config.BASE_API_URL + `critique/all_for/${id}/Submission/`;
  return (
    <td
      className="p-4 align-middle [&amp;:has([role=checkbox])]:pr-0 border-r pointer"
      key={0}
      onClick={() => (window.location.href = link)}
    >
      <a
        href={link}
        title="View critiques"
        className="inline-block w-full h-full"
      >
        <span className="font-medium text-black">{title}</span>
        <br />
        <span className="font-light text-black">({owner_pen_name})</span>
      </a>
    </td>
  );
};
const HeaderCell = ({ children }: CellProps) => {
  return (
    <th className="h-12 px-4 text-left align-middle font-medium text-black [&amp;:has([role=checkbox])]:pr-0 border-r">
      {children}
    </th>
  );
};

const Tick = () => {
  return (
    <span title="Critique completed">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#7f4f80"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="h-5 w-5 text-green-500"
      >
        <path d="M20 6 9 17l-5-5"></path>
      </svg>
    </span>
  );
};

const Circle = () => {
  return (
    <span title="Critique not started">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#0000008c"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="h-5 w-5 text-gray-400"
      >
        <circle cx="12" cy="12" r="10"></circle>
      </svg>
    </span>
  );
};

const Dash = () => {
  return (
    <span title="Critique not started">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#0000008c"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="h-5 w-5 text-gray-400"
      >
        <line x1="0" y1="12" x2="24" y2="12" />
      </svg>
    </span>
  );
};

const Glasses = () => {
  return (
    <span title="Critique in progress">
      <img
        src={config.BASE_API_URL + "static/img/glasses.png"}
        width={24}
        height={24}
      />
    </span>
  );
};

const ThumbsDown = () => {
  return <span title="Critique rejected">👎</span>;
};

const SandClock = () => {
  return <span title="Critique waiting approval">⏳</span>;
};

type RowProps = {
  children: React.ReactNode;
};
const Row = ({ children }: RowProps) => {
  return (
    <tr className="border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted">
      {children}
    </tr>
  );
};

enum CritiqueStatus {
  FinishedApproved = "FINISHED_APPROVED",
  FinishedRejected = "FINISHED_REJECTED",
  FinishedPendingApproval = "FINISHED_PENDING_APPROVAL",
  Draft = "DRAFT",
}

type SubmissionCritiquesData = {
  id: number;
  title: string;
  owner: BaseUser;
  critiques: {
    id: number;
    status: string;
    author: { id: number; pen_name: string };
    is_being_critiqued_by: boolean;
  }[];
};

const getCritiqueStatus = (
  critiques: SubmissionCritiquesData["critiques"],
  authorId: number
) => {
  const critique = critiques.find((c) => c.author.id === authorId);
  if (!critique) {
    return <Dash />;
  }
  switch (critique.status) {
    case CritiqueStatus.FinishedApproved:
      return <Tick />;
    case CritiqueStatus.FinishedRejected:
      return <ThumbsDown />;
    case CritiqueStatus.FinishedPendingApproval:
      return <SandClock />;
    case CritiqueStatus.Draft:
      return critique.is_being_critiqued_by ? <Glasses /> : <Dash />;
    default:
      return <Dash />;
  }
};

const WhoCritiqued = () => {
  const authenticationContext = useContext(AuthenticationContext);
  const [data, setData] = useState<SubmissionCritiquesData[] | null>(null);
  const [users, setUsers] = useState<Membership[] | null>(null);
  const { id } = useParams() as any;

  const fetchCritiqueData = async () => {
    const token = authenticationContext.token || "";
    const url: string = `api/v1.0/group/${id}/who-critiqued/`;
    const response = await sendRequest({
      url,
      method: "GET",
      token: token,
      body: null,
    });
    setData(response);
  };

  const fetchUsers = async () => {
    const token = authenticationContext.token || "";
    const url: string = `api/v1.0/group/${id}/members/`;
    const response = await sendRequest({
      url,
      method: "GET",
      token: token,
      body: null,
    });
    setUsers(response);
  };

  useEffect(() => {
    if (authenticationContext.token) {
      fetchCritiqueData();
      fetchUsers();
    }
  }, [authenticationContext]);

  return (
    <div className="ink-who-critiqued container">
      <div className="flex flex-col space-y-1.5 p-6">
        <h2>Critiques for current submissions</h2>
      </div>
      <div className="p-6">
        <div className="relative w-full overflow-auto">
          <div className="relative w-full overflow-auto">
            <table className="w-full caption-bottom text-sm">
              <thead className="[&amp;_tr]:border-b">
                <tr className="border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted">
                  <HeaderCell key={0}>User</HeaderCell>
                  {users?.map((user) => (
                    <HeaderCell key={user.id}>{user.pen_name}</HeaderCell>
                  ))}
                </tr>
              </thead>
              <tbody className="[&amp;_tr:last-child]:border-0">
                {data?.map((submission) => (
                  <Row key={submission.id}>
                    <TitleCell
                      id={submission.id}
                      title={submission.title}
                      owner_pen_name={submission.owner.pen_name}
                    />
                    {users?.map((user) => (
                      <Cell
                        key={user.user}
                        disabled={user.user == submission.owner.id}
                      >
                        {getCritiqueStatus(submission.critiques, user.user)}
                      </Cell>
                    ))}
                  </Row>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhoCritiqued;