import React from "react";
import { Button, Col, Card, Container, Row } from "react-bootstrap";
import ReactPlaceholder from "react-placeholder";
import config from "../../../../config";
import '../GroupProfile.scss';
import GroupGenres from '../components/GroupGenres';
import GroupMember from "pages/groups/public-profile/components/GroupMember";
import { useSelector } from "react-redux";
import { CurrentUserState } from "types";

interface GroupProfileViewProps {
  groupProfileData: any;
}

const GroupPublicProfileView: React.FC<GroupProfileViewProps> = ({ groupProfileData }) => {
  const baseUrl = config.BASE_API_URL || "";
  const groupNameEncoded = encodeURIComponent(groupProfileData.name);
  const user = useSelector((state: { user: CurrentUserState }) => state.user);

  const getLastActivityClass = (lastActivity: string) => {
    const diff = new Date().getTime() - new Date(lastActivity).getTime();
    const daysDiff = diff / (1000 * 3600 * 24);

    if (daysDiff < 7) {
      return "active";
    } else if (daysDiff < 30) {
      return "semi-active";
    } else {
      return "inactive";
    }
  };

  const lastActivityClass = getLastActivityClass(groupProfileData?.last_activity);

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return `${date.getDate()}/${date.toLocaleString('default', { month: 'short' })}`;
  };

  const imgUrl = groupProfileData?.avatar
    ? groupProfileData?.avatar?.startsWith("http")
      ? groupProfileData?.avatar
      : baseUrl + groupProfileData?.avatar
    : baseUrl + "/static/img/bg-group-profile-picture.png";

  return (
    <Container className="ink-profile">
      <Row>
        <Col sm={3}>
          <Card className="sidebar">
            <Card.Body>
              <ReactPlaceholder
                type="rect"
                className="group-picture"
                ready={groupProfileData != null}
              >
                <img src={imgUrl} />
                <div>
                  <b>
                    {groupProfileData?.members?.length || 0} Members
                  </b>
                </div>
                <div>
                  <b>
                    Seeking {groupProfileData?.min_size}-{groupProfileData?.max_size} Members
                  </b>
                </div>
              </ReactPlaceholder>
              <div className="section">
                <Button
                  href={`${config.BASE_API_URL}messages/compose/(${groupProfileData.owner}/${groupNameEncoded}/`}
                  className="btn">
                  Request Invite
                </Button>
              </div>
              <div className="section">
                <div id="settings" className="">
                  <p className="p-small">
                    <strong>Sexual content:</strong>{" "}
                    {groupProfileData?.sexual_content_display}
                    <a
                      href="#"
                      className="ico-help bot"
                      title={`What level of sexual content is acceptable in submissions to the group?
                      Minimal – Nothing more than kissing.
                      Mild – May or may not include lovemaking. Sex is implied rather than explicit.
                      Hot–Ranges from lovemaking to explicit sex. Most romance novels fall into this category.
                      Scorcher – Borders on erotic. Very graphic sex.`}
                      data-placement="right"
                    ></a>
                  </p>
                  <p className="p-small">
                    <strong>Violent content:</strong>{" "}
                    {groupProfileData?.violence_display}{" "}
                    <a
                      href="#"
                      className="ico-help"
                      title={`What level of violence is acceptable in submissions to the group?
                      None
                      Minor- little or brief violence
                      Mild- several scenes or scenes of longer duration
                      Significant- descriptive violence throughout
                      Heavy – violence is intense or gory`}
                      data-placement="right"
                    ></a>
                  </p>
                  <p className="p-small">
                    <strong>Word count max:</strong>{" "}
                    {groupProfileData?.max_words || "N/A"}{" "}
                    <a
                      href="#"
                      className="ico-help"
                      title="The max word count is the maximum number of words for a submission."
                      data-placement="right"
                    ></a>
                  </p>
                  <p className="p-small">
                    <strong>Critique ratio:</strong>{" "}
                    {groupProfileData?.critique_estimate}{" "}
                    <a
                      href="#"
                      className="ico-help"
                      title="The critique ratio is the number of credits group members use to submit their work (each critique given earns 1 credit)."
                      data-placement="right"
                    ></a>
                  </p>
                  <p className="p-small">
                    <strong>Critique window:</strong>{" "}
                    {groupProfileData?.critique_time_window} days{" "}
                    <a
                      href="#"
                      className="ico-help"
                      title="The critique window is the number of days group members have to critique submissions."
                      data-placement="right"
                    ></a>
                  </p>
                  <p className="p-small">
                    <strong>Review window:</strong>{" "}
                    {groupProfileData?.review_time_window} days{" "}
                    <a
                      href="#"
                      className="ico-help"
                      title="The review window is the number of days group members can review critiques and participate in critique Q&A. It begins after the critique period ends."
                      data-placement="right"
                    ></a>
                  </p>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Row>
            <div className="col-md-9 group">
              <h1>{groupProfileData?.name}
                <span className={`last-activity ${lastActivityClass}`}> &#8226; Last activity {formatDate(groupProfileData?.last_activity)}</span>
              </h1>
            </div>
          </Row>
          <Row>
            <div dangerouslySetInnerHTML={{ __html: groupProfileData?.description }} />
          </Row>
          <Row>
            {groupProfileData.genres2?.length > 0 && (
              <GroupGenres groupProfileData={groupProfileData} />
            )}
          </Row>
          <Row>
            <h4>GOALS</h4>
            <div>{groupProfileData?.goal}</div>
          </Row>
          <Row>
            <h4>ADMISSION POLICIES</h4>
            <div>{groupProfileData?.admissions_policies}</div>
          </Row>
          <Row>
            <h4>WHAT WE'RE LOOKING FOR IN MEMBERS</h4>
            <div>{groupProfileData?.members_requirements}</div>
          </Row>
          {user.isAuthenticated && (
            <Row>
              <div className="group-members-list public" id="group-list">
                <h4>Members</h4>
                <ul id="groupMemberList">
                  {groupProfileData ? (
                    groupProfileData.members?.map((member: any) => {
                      member.isFacilitator = groupProfileData.owners.includes(Number(member.user_id))
                      member.isOwner = member.user_id == groupProfileData.owner;
                      return <GroupMember key={member.id} member={member} />;
                    })
                  ) : (
                    <>
                      <GroupMember key={1} />
                      <GroupMember key={2} />
                      <GroupMember key={3} />
                    </>
                  )}
                </ul>
              </div>
            </Row>
          )}
        </Col>
      </Row>
      <div className="mt-3"></div>
    </Container>
  );
}

export default GroupPublicProfileView;