import { useState } from "react";
import {
  IVTable,
  IVTableBody,
  IVTableHead,
  IVTr,
  IVTd,
  IVTableSeparator,
} from "./groupDetailsTabTable";
import { useSelector } from "react-redux";
import { CurrentUserState, SubmissionInCritique } from "types";
import {
  displayCondensedDate,
  displayShortTime,
  getTimezone,
} from "pages/events/utils";
import DeleteSubmissionModal from "./deleteSubmissionModal";
import EditCritiqueModal from "./editCritiqueModal";
import MoveToReviewModal from "./moveToReviewModal";
import EditDueDateModal from "./groupDetailEditDueDate";
import config from "config";
import { Link } from "react-router-dom";

const SubmissionCritiqueStatus = ({ submission }: { submission: any }) => {
  const status = submission.status_for_user;
  if (status === "Read Critiques") {
    return (
      <Link
        className="btn submission green"
        to={`/critique/all_for/${submission.id}/Submission/`}
        role="button"
      >
        {status}
      </Link>
    );
  }
  if (submission.user_finished_critique || status === "Done") {
    return (
      <Link to={`/critique/all_for/${submission.id}/Submission/`}>Done</Link>
    );
  }
  if (status === "Give Critique") {
    return (
      <Link
        className="btn submission blue"
        to={`/critique/edit/${submission.id}/`}
        role="button"
      >
        {status}
      </Link>
    );
  }
  if (status === "Finish Critique") {
    return (
      <Link
        className="submission btn purple"
        to={`/critique/edit/${submission.id}/`}
        role="button"
      >
        {status}
      </Link>
    );
  }
  if (status === "Submitted") {
    return (
      <Link
        className="btn-default submission"
        to={`/critique/all_for/${submission.id}/Submission/`}
        role="button"
      >
        {status}
      </Link>
    );
  }

  return (
    <Link to={`/critique/edit/${submission.id}/`}>
      {submission.status_for_user}
    </Link>
  );
};

const SubmissionInCritiqueTab = ({
  submission,
  onDelete,
  onEdit,
  onMoveToReview,
}: {
  submission: SubmissionInCritique;
  onDelete: (submission: SubmissionInCritique) => void;
  onEdit: (submission: SubmissionInCritique) => void;
  onMoveToReview: (submission: SubmissionInCritique) => void;
}) => {
  const userState = useSelector(
    (state: { user: CurrentUserState }) => state.user
  );
  const status = submission.status_for_user;
  const finished = submission.user_finished_critique;
  const critiquing =
    (status === "Give Critique" && !finished) || status === "Finish Critique";

  const [showEditCritiqueModal, setShowEditCritiqueModal] = useState(false);

  return (
    <IVTr>
      <IVTd>
        <div>
          {displayCondensedDate(submission.due_date).slice(0, -3)}
          <br />
          {displayShortTime(submission.due_date)}
          <br />({getTimezone()})
          <br />
          {submission.is_group_owner && (
            <a
              role="button"
              data-target="#myModal5"
              data-toggle="modal"
              onClick={() => onEdit(submission)}
            >
              Edit
            </a>
          )}
        </div>
      </IVTd>

      <IVTd>
        <a
          className="cutoff"
          href={
            userState.data.id !== submission.author.id &&
            !(submission.user_finished_critique || status === "Done")
              ? `/critique/edit/${submission.id}/`
              : `/critique/all_for/${submission.id}/Submission/`
          }
        >
          {submission.title}
        </a>
      </IVTd>

      <IVTd>
        <Link to={`/profile/view/${submission.author.profile_id}`}>
          {userState.data.id === submission.author.id
            ? "Me"
            : submission.author.pen_name}
        </Link>
      </IVTd>

      <IVTd>
        <SubmissionCritiqueStatus submission={submission} />
        {submission.is_being_critiqued_by_anyone &&
          (
            <>
              <img
                src={config.BASE_API_URL + "static/img/glasses.png"}
                width={24}
                height={24}
                style={{ marginLeft: "10px" }}
              />
            </>
          )}
      </IVTd>

      <IVTd style={{ textAlign: "center" }}>
        {submission.finished_critiques_count}
      </IVTd>

      <IVTd className="">
        {submission.can_be_removed && (
          <>
            <div className="pl-4">
              <a href="#" onClick={() => onDelete(submission)}>
                Remove
              </a>
            </div>
            <div className="pl-4">
              <a href="#" onClick={() => onMoveToReview(submission)}>
                Move to Review
              </a>
            </div>
          </>
        )}
        {(submission.status_for_user === "Done" ||
          submission.user_finished_critique) && (
          <>
            <div className="pl-4">
              <a
                className="submission"
                href="#"
                role="button"
                onClick={() => setShowEditCritiqueModal(true)}
              >
                Edit Critique
              </a>
            </div>
            <EditCritiqueModal
              submission={submission}
              show={showEditCritiqueModal}
              onHide={() => {
                setShowEditCritiqueModal(false);
              }}
            />
          </>
        )}
      </IVTd>
    </IVTr>
  );
};

type GroupDetailCritiqueTabProps = {
  submissions: SubmissionInCritique[] | null;
  onChange?: () => void;
};

export default function GroupDetailCritiqueTab({
  submissions,
  onChange,
}: GroupDetailCritiqueTabProps) {
  const userState = useSelector(
    (state: { user: CurrentUserState }) => state.user
  );
  const [submissionToDelete, setSubmissionToDelete] =
    useState<SubmissionInCritique | null>(null);

  const [submissionToEdit, setSubmissionToEdit] =
    useState<SubmissionInCritique | null>(null);

  const [submissionToMoveToReview, setSubmissionToMoveToReview] =
    useState<SubmissionInCritique | null>(null);

  return (
    <div
      className="tab-pane active current-tab"
      id="current"
      style={{ maxWidth: "100%", overflowX: "scroll" }}
    >
      <IVTable>
        <IVTableHead>
          <IVTr>
            <th scope="col" style={{ width: "60px" }}>
              Due
            </th>
            <th scope="col" style={{ width: "230px" }}>
              Title
            </th>
            <th scope="col" style={{ width: "130px" }}>
              Author
            </th>
            <th scope="col" style={{ width: "150px" }}>
              Status
            </th>
            <th scope="col" style={{ width: "60px" }}>
              Critiques
            </th>
            <th scope="col" style={{ width: "120px", paddingLeft: "1.5rem" }}>
              More
            </th>
          </IVTr>
        </IVTableHead>
        <IVTableBody>
          {submissions
            ?.filter((submission) => submission.author.id === userState.data.id)
            ?.map((submission) => (
              <SubmissionInCritiqueTab
                key={submission.id}
                submission={submission}
                onDelete={setSubmissionToDelete}
                onEdit={setSubmissionToEdit}
                onMoveToReview={setSubmissionToMoveToReview}
              />
            ))}

          <IVTableSeparator cols={6} />

          {submissions
            ?.filter((submission) => submission.author.id != userState.data.id)
            ?.map((submission) => (
              <SubmissionInCritiqueTab
                key={submission.id}
                submission={submission}
                onDelete={setSubmissionToDelete}
                onEdit={setSubmissionToEdit}
                onMoveToReview={setSubmissionToMoveToReview}
              />
            ))}
        </IVTableBody>
      </IVTable>
      {submissionToDelete && (
        <DeleteSubmissionModal
          submission={submissionToDelete}
          show={true}
          onHide={(deleted) => {
            setSubmissionToDelete(null);
            if (deleted) {
              onChange && onChange();
            }
          }}
        />
      )}
      {submissionToMoveToReview && (
        <MoveToReviewModal
          submission={submissionToMoveToReview}
          show={true}
          onHide={(moved) => {
            setSubmissionToMoveToReview(null);
            if (moved) {
              onChange && onChange();
            }
          }}
        />
      )}
      {submissionToEdit && (
        <EditDueDateModal
          submission={submissionToEdit}
          show={true}
          onHide={(edited) => {
            setSubmissionToEdit(null);
            if (edited) {
              onChange && onChange();
            }
          }}
        />
      )}
    </div>
  );
}
