export interface Correspondent {
  pen_name: string;
  id: number;
  profile_id: number;
}

export interface InkedMessage {
  sender: Correspondent;
  subject: string;
  body: string;
  sent_at: string;
  recipients: Correspondent[] | null;
}

export const isReplyAll = (message: InkedMessage): boolean => {
  return message.recipients !== null;
}