import React, { useContext, useEffect, useState } from 'react';
import { Modal, Button, Form, Row, Col, Spinner } from 'react-bootstrap';
import Select from 'react-select';
import { toast } from 'react-toastify';
import useFetch from 'custom-hooks/useFetch';
import AuthenticationContext from 'contexts/AuthenticationContext';
import { BaseUser } from 'types';

interface TransferGroupModalProps {
  show: boolean;
  handleClose: () => void;
  groupId: string;
  groupUrl: string;
}

const TransferGroupModal: React.FC<TransferGroupModalProps> = ({ show, handleClose, groupId, groupUrl }) => {
  const authenticationContext = useContext(AuthenticationContext);
  const [state, fetchData] = useFetch();
  const [owners, setOwners] = useState<BaseUser[]>([]);
  const [selectedOwner, setSelectedOwner] = useState<{ value: string; label: string } | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchData({
      url: `api/v1.0/group/${groupId}/members/`,
      method: "GET",
      token: authenticationContext.token,
    }).then((res) => {
      setOwners(res);
    });
  }, [fetchData, authenticationContext.token]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!selectedOwner) {
      toast.error('Please select a user to transfer ownership to.');
      return;
    }

    setIsLoading(true);

    const data = {
      owner: selectedOwner.value,
      groupId,
    };

    fetchData({
      url: `group/api/transfer-group-ownership/${groupId}/`,
      method: "POST",
      token: authenticationContext.token,
      body: JSON.stringify(data),
    })
      .then((response: any) => {
        setSelectedOwner(null);
        toast.success(response.message);
        // Wait 5 seconds before redirecting to group page
        setTimeout(() => {
          window.location.href = groupUrl;
        }, 5000);
      })
      .catch((error: any) => {
        if (error) {
          toast.error(error);
        } else {
          console.error('Error submitting form:', error);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const ownerOptions = owners.map((owner: BaseUser) => ({
    value: owner.id.toString(),
    label: owner.pen_name,
  }));

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton closeLabel="" className="custom-close-button">
        <Modal.Title>Transfer Group Ownership</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Who would you like to transfer group ownership to?</p>
        <form onSubmit={handleSubmit}>
          <Row>
            <Col md={12} style={{ paddingBottom: '3%' }}>
              <Form.Group controlId="formOwners">
                <Form.Label>Owners</Form.Label>
                <Select
                  value={selectedOwner}
                  onChange={setSelectedOwner}
                  options={ownerOptions}
                  className="basic-single-select"
                  classNamePrefix="select"
                  placeholder="[Select new owner]"
                  isDisabled={isLoading}
                />
              </Form.Group>
            </Col>
            <Col md={12}>
              <Button type="submit" className="btn" disabled={isLoading}>
                {isLoading ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />{' '}
                    Transferring...
                  </>
                ) : (
                  'Transfer'
                )}
              </Button>
              <Button variant="secondary" onClick={handleClose} className="btn cancel" disabled={isLoading}>
                Cancel
              </Button>
            </Col>
          </Row>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default TransferGroupModal;