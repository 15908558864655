import React from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setEventsFilters } from 'redux/eventsFiltersReducer';

interface EventTypeFiltersProps {
  types: { name: string; description: string; key: string }[];
  selectedTypes: string[];
  onTypeChange: (selected: string[]) => void;
}

const EventTypeFilters: React.FC<EventTypeFiltersProps> = ({ types, selectedTypes, onTypeChange }) => {
  const eventsFilters = useSelector((state: any) => state.eventsFilters);
  const dispatch = useDispatch();

  const toggleType = (name: string) => {
    const newSelectedTypes = selectedTypes.includes(name)
      ? selectedTypes.filter(type => type !== name)
      : [...selectedTypes, name];
    onTypeChange(newSelectedTypes);
    dispatch(setEventsFilters(
      {
        type: { ...eventsFilters.type, [name]: !(eventsFilters.type && eventsFilters.type[name]) || false },
        category: { ...eventsFilters.category },
        free: eventsFilters.free,
        available: eventsFilters.available,
      }
    ));
  };

  return (
    <>
      <h4>Type</h4>
      <p>
        <span className='button-list'>
          {types?.map((t: any) => (
            <Button
              key={t.key}
              className={"purple " + (selectedTypes.includes(t.key) ? "" : "inactive")}
              onClick={() => toggleType(t.key)}>
              {t.name}
            </Button>
          ))}
        </span>
      </p>
      {selectedTypes.length > 0 && (
        <div className='type-desc-container'>
          {types.map((t: any) => (
            selectedTypes.includes(t.key) && (
              <p key={t.key} className='type-desc'>
                <span className='name'>{t.name}:</span> <span dangerouslySetInnerHTML={{ __html: t.description }} />
              </p>
            )
          ))}
        </div>
      )}
    </>
  );
};

export default EventTypeFilters;